<template>
  <div id="app">

        <!-- <Header /> -->
        <Header v-if="getStatusMenu" />
        <Loading v-if="getStatusLoading"/> 
        <div class="content" v-else>
            <router-view />
        </div>
  </div>
</template>

 

<script>
 
import api from "./services/api";

import Header from "./components/Header";
import Loading from './components/Loading'

// Recarrega a aplicação
// window.location.reload()


export default {
    name: "App",
    // beforeRouteEnter(to, from, next){
    //     if (from.path == '') {

    //     }
    // },
    components: { Header, Loading },
    data() {
        return {
        isMenuVisible: false,
        validatingToken: true
        };
    },
    created() {
        console.log("iniciouuu");
        this.$router.push("/login");
        // this.validateToken()
    },
    methods: {
            async validateToken() {
                console.log('valitation')
                this.validatingToken = true

                const local = localStorage.getItem('user')
                const userData =  JSON.parse(local)
                this.$store.commit('auth/setUser', null)

                if(!userData) {
                    this.validatingToken = false
                    this.$router.push({ name: 'auth' })
                    return
                }

                const res = await api.get("validator_token" , userData.token)
                if(res.data) {
                    this.$store.commit('auth/setUser', userData)
                } else {
                    localStorage.removeItem('user')
                    this.$router.push({ name: 'auth' })
                }

                this.validatingToken = false
            }
    },
    computed: {
        getStatusMenu() {
        //console.log(this.$store.state.isAuth);
            return this.$store.state.auth.isMenuVisible  
            // return this.$store.state.isMenuVisible;
        },
        getStatusLoading() {
            return this.$store.state.auth.loading   
        }
    }

};
</script>





<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
</style>
