
export default {

    namespaced: true,
    state: {
        idClienteUnidade: {},
        idEvento: null
    },
    mutations: {
        setIdClienteUnidade(state, valor){
            state.idClienteUnidade = valor
        },
        setIdEvento(state, valor){
            state.idEvento = valor
        }
    }
 
}
