<template>
  <div id="dashboardContas">


    <section class="container"> 

      <div v-for="conta in contas" :key="conta.id">
        <b-card border-variant="info" :header="conta.nome" header-bg-variant="info" header-text-variant="white" align="center" style="height: 140px;">
          <p class="saldo">Saldo: {{conta.saldo}}</p>
          <p class="previsao">Previsão: {{conta.previsao}}</p>
        </b-card>
      </div>


      <!-- <div v-for="conta in contas" :key="conta.id">
        <b-card border-variant="info" :header="conta.nome" align="center" style="height: 140px;" 
        header-bg-variant="info"
        header-text-variant="white"
        >
          <p class="saldo">Saldo: {{conta.saldo}}</p>
          <p class="previsao">Previsão: {{conta.previsao}}</p>
        </b-card>
      </div> -->

    </section>  


  </div>
</template>



<script>
import api from "../services/api";
import { msg } from "@/global";
export default {

  data() {
    return {

      contas: [],   
  
    }
  },

  async created() {

    // await new Promise(r => setTimeout(r, 1000));
    this.getInfoDashboad()
  },

  methods: {


      getInfoDashboad() {

        const sedes = this.$store.state.sedes
        const idsSedes = sedes.map(item => item.id)

        api.get(`dashboad-contas/${idsSedes}`)
        .then((res) => {
          // console.log(res.data)
          this.contas = res.data

        })
        .catch((err) => { msg(err.response.data, "vermelho", 5000) });

      }


    }

};
</script>



<style scoped>

#dashboardContas {
  /* background-color: #000; */
  height: 90%;
  width: 90%;
  /* background-color: rgb(240, 240, 240); */
  padding: 35px;
  margin: 0;
}



.container {
  display: flex;
  flex-wrap: wrap;     
}

.container > div {
  margin: auto;
  margin-bottom: 5px;
}

.card {
  /* background-color: rgb(25, 155, 216); */
  padding: 0;
  margin: 7px;
  width: 300px;
  height: 150px;
}

.card-body {
  /* background-color: rgb(12, 219, 192); */
  padding: 0;
}


.saldo {
  text-align: left;
  margin: 5px;
  margin-top: 12px;
  font-weight: bold;
  font-size: 19px;
}

.previsao {
  text-align: left;
  margin: 5px;
  font-size: 19px;
}



</style>