<template>
  <div id="page-fornecedores">
    <b-list-group>
      <div>
        <h2>Gerenciar Fornecedores</h2>

        <!-- <div class="form-row  bv-example-row-flex-cols"> -->
        <!-- <b-container class="bv-example-row"> -->

            <b-row align-h="between">
                
                <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button>

                <div class="form-group col-lg-4">
                    <label><span class="text-danger"></span> Buscar</label>
                    <input class="form-control"  v-model="filtro" />
                </div>

            </b-row>

        <!-- </b-container> -->
        <!-- </div> -->
      </div>

      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="filtrar"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>

          <template #cell(Editar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../assets/edit1.png"
              alt="editar"
              height="23px"
              @click="Editar(data.item)"
            />
          </template>
          <template #cell(Apagar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="showModalApagar(data.item)"
            />
          </template>
        </b-table>
      </div>
    </b-list-group>

    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Você confirma a exclusão?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="hideModalApagar"
          >Não</b-button
        >
      </b-modal>
    </div>

    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Fornecedor" size="lg">
        <div class="row text-left col-lg-12">
          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span> Nome</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.nome"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger"></span> CNPJ / CPF</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.cnpjOuCpf"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger"></span> Categoria</label>
            <b-form-select
              v-model="form.idCategoria"
              :options="categorias"
              @change="getItensCategorias"
            ></b-form-select>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger"></span> Item</label>
            <b-form-select
              v-model="form.idItemCategoria"
              :options="itensCategorias"
            ></b-form-select>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger"></span> Forma Pagto.</label>
            <b-form-select
              v-model="form.formaPagto"
              :options="formPagtos"
            ></b-form-select>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Dia do vencimento</label>
            <b-form-select
              v-model="form.diaVencimento"
              :options="diasVencimentos"
            ></b-form-select>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Valor</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.valor"
              @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>
        

          <div class="form-group col-lg-6">
            <label><span class="text-danger"></span> Banco</label>
            <b-form-select
              v-model="form.banco"
              :options="bancos"
            ></b-form-select>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Agência</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.agencia"
              maxlength="6"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Conta bancária</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.conta"
              maxlength="15"
            ></b-form-input>
          </div>


        </div>

        <b-button class="mt-3" variant="outline-success" block @click="save"
          >Confirmar</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="hideModalAdd"
          >Cancelar</b-button
        >
      </b-modal>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { msg, acessoRestrito } from "@/global";

export default {
  name: "Forncedores",

  data() {
    return {
      dadosHeader: {},
      filtro: "",

      form: {},
      categorias: [],
      itensCategorias: [],

      fields: [
        { key: "id", label: "Código" },
        { key: "nome", label: "Fornecedor" },
        { key: "cnpjOuCpf", label: "CNPJ / CPF" },
        "Editar",
        "Apagar",
      ],

      formPagtos: [
        "Dinheiro",
        "Cheque",
        "Cartão Débito",
        "Cartão Crédito",
        "Tranferência",
        "DOC",
        "TED",
        "Pix",
      ],
      diasVencimentos: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],

      items: [],
      idFornecedor: null,

      bancos: [],

      load: true,
      perPage: 10,
      currentPage: 1,
    };
  },

  created() {

    this.getBancos()
    this.getFornecedores();
    this.getCategorias();
  },

  methods: {


    formatMoeda(event) {
      
      // console.log(event.target.value)
      let input = event.target

      var valor = input.value.replace(/,/, '')

      if (valor.length > 2)
          input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
      else   
      if (valor.length > 0 && valor.length < 4)
          input.value = valor
      else   
          input.value = ''

    },

    showModalApagar(item) {
      acessoRestrito(1013)
      this.$refs["modal-del"].show();
      this.form = item;
    },
    hideModalApagar() {
      this.$refs["modal-del"].hide();
    },

    showModalAdd() {
      acessoRestrito(1011)
      this.form = {}
      this.$refs["modal-add"].show();
    },
    hideModalAdd() {
      this.$refs["modal-add"].hide();
    },

    getBancos() {

      api
        .get(`bancos`)
        .then((res) => {
          this.bancos = res.data
        })
        .catch((err) => {
          msg(err.response.data, "vermelho", 5000);
        });
    },

    getFornecedores() {
      acessoRestrito(1010)

      this.load = true;
      const idSede = this.$store.state.sede.id 

      api
        .get(`fornecedores/${idSede}`)
        .then((res) => {
          this.load = false;
          this.items = res.data;
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },

    getCategorias() {

      this.load = true;
      const idSede = this.$store.state.sede.id 

      api
        .get(`fornecedores/categorias/${idSede}`)
        .then((res) => {
          this.load = false;
          this.categorias = res.data;
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },

    getItensCategorias() {

      api
        .get(`fornecedores/categoria/itens/${this.form.idCategoria}`)
        .then((res) => {
          this.itensCategorias = res.data;
        })
        .catch((err) => {
          msg(err.response.data, "vermelho", 5000);
        });
    },

    apagar() {
      acessoRestrito(1013)
      this.$refs["modal-del"].hide();

      api
        .delete(`fornecedor/${this.form.id}`)
        .then(() => {
          msg("FORNECEDOR REMOVIDO COM SUCESSO.", "verde", 3000);
          this.getFornecedores();
        })
        .catch((err) => msg(err.response.data, "vermelho", 3000));
    },

    Editar(item) {
      acessoRestrito(1012)
      this.form = item;
      this.$refs["modal-add"].show();
    },

    save() {
      this.form.idSede = this.$store.state.sede.id 

      api
        .post("fornecedor", this.form)
        .then((res) => {
          this.hideModalAdd();
          msg(res.data, "verde", 5000);
          this.getFornecedores();
          this.form = {};
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },
  },

    computed: {

        getIdClienteUnidade() {
            return this.$store.state.escala.idClienteUnidade;
        },

        filtrar() {

            // console.log(this.items)
            
            if (this.filtro.trim() == '') return this.items
                
            let listaFiltrada = []
            this.items.forEach((item) => {
                if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
                if(item.cnpjOuCpf && item.cnpjOuCpf.toString().match(this.filtro)) listaFiltrada.push(item) 
            })

            return listaFiltrada
        },
    },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */

.btnRight {
  /* float: right; */
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  /* font-size: 18px; */
  font-weight: bold;
}

#page-fornecedores h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

    

}
</style>