<template>
  <div id="page-contas">
    <b-list-group>
      <div>
        <h2>Gerenciar Contas</h2>

        <!-- <div class="form-row  bv-example-row-flex-cols"> -->
        <!-- <b-container class="bv-example-row"> -->

            <b-row align-h="between">
                
                <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button>

                <div class="form-group col-lg-4">
                    <label><span class="text-danger"></span> Buscar</label>
                    <input class="form-control"  v-model="filtro" />
                </div>

            </b-row>

        <!-- </b-container> -->
        <!-- </div> -->
      </div>

      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="filtrar"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>

          <template #cell(Editar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../assets/edit1.png"
              alt="editar"
              height="23px"
              @click="Editar(data.item)"
            />
          </template>
          <template #cell(Apagar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="showModalApagar(data.item)"
            />
          </template>
        </b-table>
      </div>
    </b-list-group>

    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Você confirma a exclusão?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="hideModalApagar"
          >Não</b-button
        >
      </b-modal>
    </div>

    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Conta" size="lg">
        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-8">
            <label><span class="text-danger">*</span> Nome da conta</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.nome"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-4">
            <label><span class="text-danger"></span> Saldo</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.saldo"
              @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>

          <!-- <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Limite de Crédito</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.limiteCredito"
              @keyup="formatMoeda($event)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger"></span> Saldo + Limite</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.saldoMaisLimite"
            ></b-form-input>
          </div> -->


        </div>
        <b-button class="mt-3" variant="outline-success" block @click="save"
          >Confirmar</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="hideModalAdd"
          >Cancelar</b-button
        >
      </b-modal>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { msg, acessoRestrito } from "@/global";

export default {
  name: "Pagadores",

  data() {
    return {
      dadosHeader: {},
      filtro: '',

      form: {},

      fields: [
        { key: "nome", label: "Conta" },
        { key: 'limiteCredito', label: 'Limite de Crédito', formatter: value => {
          const valores = value.toString().split('.')

          if (valores.length > 1 ) return 'R$ '+valores[0]+','+valores[1]

          return 'R$ '+value+',00'
        }, thClass: 'text-right', tdClass: 'text-right'},
        { key: 'saldo', label: 'Saldo total', formatter: value => {
          const valores = value.toString().split('.')

          if (valores.length > 1 ) return 'R$ '+valores[0]+','+valores[1]

          return 'R$ '+value+',00'
        }, thClass: 'text-right', tdClass: 'text-right'},
        { key: "Editar", label: "Editar", thClass: 'text-right', tdClass: 'text-right' },
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],

      items: [], 

      load: true,
      perPage: 10,
      currentPage: 1,
    };
  },

  created() {
    this.getContas();
  },

  methods: {

    showModalApagar(item) {
      this.$refs["modal-del"].show();
      this.form = item;
    },
    hideModalApagar() {
      this.$refs["modal-del"].hide();
    },

    showModalAdd() {
      acessoRestrito(1001)
      this.form = {}
      this.$refs["modal-add"].show()
    },
    hideModalAdd() {
      this.$refs["modal-add"].hide();
    },

    formatMoeda(event) {
      
        // console.log(event.target.value)
        let input = event.target

        var valor = input.value.replace(/,/, '')

        if (valor.length > 2)
            input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
        else   
        if (valor.length > 0 && valor.length < 3)
            input.value = valor
        else   
            input.value = ''

    },

    getContas() {
      this.load = true;
      const idSede = this.$store.state.sede.id 

      api
        .get(`contas/${idSede}`)
        .then((res) => {
          this.load = false;
          this.items = res.data;
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },

    apagar() {
      acessoRestrito(1003)
      this.$refs["modal-del"].hide();

      api
        .delete(`conta/${this.form.id}`)
        .then(() => {
          msg("CONTA REMOVIDA COM SUCESSO.", "verde", 3000);
          this.getContas();
        })
        .catch((err) => msg(err.response.data, "vermelho", 3000));
    },

    Editar(item) {
      acessoRestrito(1002)
      this.form = item
      this.$refs["modal-add"].show()
    },

    save() {

      this.form.idSede = this.$store.state.sede.id 
      // this.form.conta = this.$store.state.conta.id 

      api
        .post("conta", this.form)
        .then((res) => {
          this.hideModalAdd();
          msg(res.data, "verde", 5000);
          this.getContas();
          this.form = {};
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },
  },

    computed: {

        filtrar() {

            // console.log('opaa')
            
            if (this.filtro.trim() == '') return this.items
                
            let listaFiltrada = []
            this.items.forEach((item) => {
                if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
                if(item.saldo && item.saldo.toString().match(this.filtro)) listaFiltrada.push(item) 
            })

            return listaFiltrada
        },
    },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */

.btnRight {
  /* float: right; */
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  /* font-size: 18px; */
  font-weight: bold;
}

#page-contas h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

    

}
</style>