<template> 
<div>  
  


  <div class="form-group col-lg-12">
    <label><span class="text-danger"></span> Digite o titulo do relatório - (opcional)</label>
    <b-form-input
      id="input-1"
      class="text-uppercase"
      v-model="titulo"
    ></b-form-input>
  </div>


  <div class="tabelaLancamentos" v-show="false">
      <b-table id="tabelaLancamentos" :items="lancamentos" :fields="fieldsLancamentos"></b-table>
  </div>
    

  <!-- <b-card
    class="mt-3"
      header="Resultado">
    <pre class="m-0">{{ hospede }}</pre>
  </b-card> -->

      

  <b-container class="bv-example-row">
    <b-row align-h="start">
      <b-button class="mt-3 botao-confirmar2" variant="success" @click="gerarPdf()">Imprimir</b-button>
      <b-button class="mt-3 botao-confirmar2" variant="danger" @click="cancelar()">Cancelar</b-button>
    </b-row>
  </b-container>


</div> 
</template>

<script>
// import api from "@/services/api";
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import dayjs from 'dayjs';

export default {
name: 'ModalImprimir', 
props: {
  lancamentos: []
},

data() {
  return {  

    load: false,

    titulo: '',


    fieldsLancamentos: [
      {key: 'fornecedor', label: 'Fornecedor / Pagador'},
      {key: 'categoria', label: 'Categoria'},
      {key: 'vencimento', label: 'Vencimento', formatter: value => { 
        return dayjs(value).format('DD/MM/YYYY')
      }},
      {key: 'baixaData', label: 'Pagamento', formatter: value => { 
        return dayjs(value).format('DD/MM/YYYY')
      }},
      {key: 'tipoLancamento', label: 'Tipo'},
      {key: 'valor', label: 'Valor', formatter: value => { 
        return 'R$ ' + value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', currencyDisplay: 'name' });
      }},
      
    ],


  }

},
created() { 
  console.log(this.lancamentos)
},

methods: {

  msgNotification(texto, tipo, tempo) {
    this.$toast.open({
      message: texto,
      type: tipo,
      position: 'top-right',
      duration: tempo
      // all of other options may go here
    })
  },



  cancelar() {
    this.$emit('EMIT_fechar_modal')
  },


  getImgUrl(nomeFile) {
    var images = require.context('../../../assets/', false, /\.png$/)
    return images('./' + nomeFile + ".png")
  },


  async gerarPdf() {

    
    var doc = jsPDF('p', 'mm', [210, 297]);
    // var doc = jsPDF('l', 'mm', [210, 297]);



    // Topo
    //------------------------------------------------------------------
    var img = new Image()
    img.src = this.getImgUrl('logoSind') 
    doc.addImage(img, 'png', 9, 8, 27, 27) 

    const width = doc.internal.pageSize.getWidth()
    const height = doc.internal.pageSize.getHeight()

    doc.setFontSize(15).setFont(undefined, 'bold'); 
    doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14, { align: 'center' })
    doc.text('Ferroviarias da Zona Sorocabana', width/2, 20, { align: 'center' })
    
    doc.setFontSize(12).setFont(undefined, 'bold'); 
    doc.text(this.titulo, width/2, 28, { align: 'center' })
    

    doc.setDrawColor(0, 0, 0);
    doc.line(0, 36, 500, 36);


    doc.setFontSize(14);          
    doc.text('Lançamentos', width/2, 46, { align: 'center' })

    //------------------------------------------------------------------
                          


    // Tabela Lançamentos
    //------------------------------------------------------------------
    doc.autoTable({
        html: '#tabelaLancamentos',
        tableHegth: height + 70,
        margin: {top: 49, left: 14}, 
        theme: 'grid',
    }); 


    // var finalY = doc.lastAutoTable.finalY;
    //------------------------------------------------------------------



    // Rodapé
    //------------------------------------------------------------------
    
    var pageCount = doc.internal.getNumberOfPages();
    for(var i = 0; i < pageCount; i++) { 
      doc.setPage(i); 
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
      doc.setFontSize(9);
      doc.text(10, (height - 10), 'Data: ' + dayjs().format('DD/MM/YYYY'));
      doc.text(40, (height - 10), 'Hora: ' + dayjs().format('HH:mm'));
      doc.text('Pág ' + pageCurrent + '/' + pageCount, 190, (height - 10));
    }
    //------------------------------------------------------------------


    doc.save(`Reserva nº g.pdf`)
    this.cancelar()

  },

}

  
};

</script>

  
<style scoped>


.tabela {
  width: 80vw;
  margin-top: 0px;
}



.botao-confirmar2 {
  width: 170px; 
  margin-left: 10px;
  margin-bottom: 15px;
}

.cursor { 
  cursor: pointer;
}

.linha {
  width: 100%;
  height: 1px;
  background-color: #17a2b8;
}

</style>