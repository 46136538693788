<template>
  <div id="dashboard">

        <!-- <div class="row">
          <div class="col-md-4">
            <div id="chart-spark1">
              <apexchart type="area" height="160" :options="chartOptionsSpark3" :series="seriesSpark3"></apexchart>
            </div>
          </div>
        </div> -->


    <div>


      <div>
        <b-card-group deck>

          <b-card  text-variant="black" header="Mês atual" class="text-center">
            <apexchart type="area" height="160" :options="chartOptionsSaldo" :series="seriesSaldo"></apexchart>
            <apexchart type="bar" height="150" :options="chartOptionsDuplex" :series="seriesDuplex"></apexchart>
          </b-card>

          <b-card  text-variant="black" header="Receita mensal" class="text-center">
            <apexchart type="bar" height="350" :options="chartOptionsReceitasMensais" :series="seriesReceitasMensais"></apexchart>
          </b-card>

          <b-card  text-variant="black" header="Despesa mensal" class="text-center">
            <apexchart type="bar" height="350" :options="chartOptionsDespesasMensais" :series="seriesDespesasMensais"></apexchart>
          </b-card>


          <b-card  text-variant="black" header="Vencimentos" class="text-center">
            <b-card-group deck>

              <b-card  text-variant="black" header="a receber" class="text-center">
                <apexchart type="area" height="160" :options="chartOptionsReceitasHoje" :series="seriesReceitasHoje"></apexchart>
                <apexchart type="area" height="160" :options="chartOptionsReceitasVencidas" :series="seriesReceitasVencidas"></apexchart>
              </b-card>

              <b-card  text-variant="black" header="a pagar" class="text-center">
                <apexchart type="area" height="160" :options="chartOptionsDespesasHoje" :series="seriesDespesasHoje"></apexchart>
                <apexchart type="area" height="160" :options="chartOptionsDespesasVencidas" :series="seriesDespesasVencidas"></apexchart>
              </b-card>

            </b-card-group>
         </b-card>

          <!-- <b-card  text-variant="black" header="Receitas à receber" class="text-center">
            <apexchart type="area" height="160" :options="chartOptionsReceitasHoje" :series="seriesReceitasHoje"></apexchart>
            <apexchart type="area" height="160" :options="chartOptionsReceitasVencidas" :series="seriesReceitasVencidas"></apexchart>
          </b-card>

          <b-card  text-variant="black" header="Despesas à pagar" class="text-center">
            <apexchart type="area" height="160" :options="chartOptionsDespesasHoje" :series="seriesDespesasHoje"></apexchart>
            <apexchart type="area" height="160" :options="chartOptionsDespesasVencidas" :series="seriesDespesasVencidas"></apexchart>
          </b-card> -->

        </b-card-group>
      </div>
      <div class="mt-3">
        <b-card-group deck>
          
          <!-- <b-card  text-variant="black" header="Consolidado" class="text-center">
            <apexchart type="bar" height="350" :options="optionsLinesConsolidadas" :series="seriesLinesConsolidados"></apexchart>
          </b-card> -->

          <b-card  text-variant="black" header="Categorias de receitas do mês" class="text-center" v-if="visivel">
            <apexchart type="pie" width="95%" :options="optionsCategoriasReceitas" :series="seriesCategoriasReceitas"></apexchart>
          </b-card>

          <b-card  text-variant="black" header="Categorias de despesas do mês" class="text-center" v-if="visivel">
            <apexchart type="pie" width="95%" :options="optionsCategoriasDespesas" :series="seriesCategoriasDespesas"></apexchart>
          </b-card>

          

        </b-card-group>
      </div>

    </div>  


    


  </div>
</template>



<script>
import api from "../services/api";
import { msg } from "@/global";
import VueApexCharts from 'vue-apexcharts'
export default {
  components: { apexchart: VueApexCharts },

  data() {
    return {


      visivel: false,



      seriesSaldo: [{
            data: []
      }],
      chartOptionsSaldo: null,

      seriesDuplex: [{
        data: []
      }],
      chartOptionsDuplex: null,




      seriesReceitasHoje: [{
            data: []
      }],
      chartOptionsReceitasHoje: null,


      seriesDespesasHoje: [{
            data: []
      }],
      chartOptionsDespesasHoje: null,


      seriesReceitasVencidas: [{
            data: []
      }],
      chartOptionsReceitasVencidas: null,


      seriesDespesasVencidas: [{
            data: []
      }],
      chartOptionsDespesasVencidas: null,

    


      //--------------------


    seriesReceitasMensais: [{
      name: 'Receitas',
      data: []
    }],
    chartOptionsReceitasMensais: null,


    seriesDespesasMensais: [{
      name: 'Despesas',
      data: []
    }],
    chartOptionsDespesasMensais: null,

      //--------------------


    seriesLinesConsolidados: [
      {
        name: 'Receitas',
        data: []
      }, {
        name: 'Despesas',
        data: []
      }
    ],
    optionsLinesConsolidadas: null,


    


//--------------------------------------

      
      seriesCategoriasReceitas: [],
      optionsCategoriasReceitas: null,




      seriesCategoriasDespesas: [44, 55, 13, 43, 22],
      optionsCategoriasDespesas: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Mensalidades', 'Team B', 'Team C', 'Team D', 'Team E'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },





      // seriesCategoriasDespesas: [44, 55, 13, 43, 22],
      // optionsCategoriasDespesas: {
      //   chart: {
      //     width: 380,
      //     type: 'pie',
      //   },
      //   labels: ['Mensalidades', 'Team B', 'Team C', 'Team D', 'Team E'],
      //   responsive: [{
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200
      //       },
      //       legend: {
      //         position: 'bottom'
      //       }
      //     }
      //   }]
      // },


      //--------------------

      
        
  
    }
  },

  async created() {

    // await new Promise(r => setTimeout(r, 1000));
    this.getInfoDashboad()
  },

  methods: {

      updateChart() {
      const max = 90;
      const min = 20;
      const newData = this.series[0].data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min
      })
      // In the same way, update the series option
      this.series = [{
        data: newData
      }]
      },

      getInfoDashboad() {

        // const idSede = this.$store.state.sede.id
        const idConta = this.$store.state.conta.id

        api
        .get(`dashboad/${idConta}`)
        .then((res) => {
          // console.log(res.data)

          this.seriesSaldo = [{
            data: res.data.saldo.dados
          }],
          this.chartOptionsSaldo = res.data.saldo.grafico


          this.seriesDuplex = [{
            data: res.data.receitaDesepesasTotais.dados
          }],
          this.chartOptionsDuplex = res.data.receitaDesepesasTotais.grafico





          this.seriesReceitasMensais = [{
            name: 'Receitas',
            data: res.data.receitasMensais.dados
          }],
          this.chartOptionsReceitasMensais = res.data.receitasMensais.grafico


          this.seriesDespesasMensais = [{
            name: 'Despesas',
            data: res.data.despesasMensais.dados
          }],
          this.chartOptionsDespesasMensais = res.data.despesasMensais.grafico








          this.seriesReceitasHoje = [{
            data: res.data.receitasPagarHoje.dados
          }],
          this.chartOptionsReceitasHoje = res.data.receitasPagarHoje.grafico


          this.seriesDespesasHoje = [{
            data: res.data.despesasPagarHoje.dados
          }],
          this.chartOptionsDespesasHoje = res.data.despesasPagarHoje.grafico




          this.seriesReceitasVencidas = [{
            data: res.data.receitasPagarVencidas.dados
          }],
          this.chartOptionsReceitasVencidas = res.data.receitasPagarVencidas.grafico


          this.seriesDespesasVencidas = [{
            data: res.data.despesasPagarVencidas.dados
          }],
          this.chartOptionsDespesasVencidas = res.data.despesasPagarVencidas.grafico



          this.seriesLinesConsolidados = [
            {
              name: "Receitas",
              data: res.data.consolidados.dadosReceitas
            },
            {
              name: "Despesas",
              data: res.data.consolidados.dadosDespesas
            }
          ],
          this.optionsLinesConsolidadas = res.data.consolidados.grafico







          this.seriesCategoriasReceitas = res.data.receitasCategorias.dados
          // this.optionsCategoriasReceitas.labels = res.data.receitasCategorias.labels
          this.optionsCategoriasReceitas = res.data.receitasCategorias.grafico

          this.seriesCategoriasDespesas = res.data.despesasCategorias.dados
          this.optionsCategoriasDespesas = res.data.despesasCategorias.grafico


          this.visivel = true


          

        })
        .catch((err) => { msg(err.response.data, "vermelho", 5000) });

      }

    }



};
</script>



<style scoped>
#dashboard {
  height: 91vh;
  width: 90vw;
  /* background-color: rgb(240, 240, 240); */
  padding: 35px;
}

.card-body {
  padding: 0;
}




</style>