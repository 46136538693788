import Vue from 'vue'
import Vuex from 'vuex'

import escala from './escala'
import auth from './auth'

Vue.use(Vuex)

const store = new Vuex.Store({
    // strict: true,
    devtools: false,
    namespaced: true,
    state: {
        sede: null,
        conta: null,
        sedes: [],
        contas: []
    },
    mutations: {


        setSedes(state, sedes) {
            state.sedes = sedes
        },

        setSede(state, sede) {
            state.sede = sede
        },

        setContas(state, contas) {
            state.contas = contas
        },

        setConta(state, conta) {
            state.conta = conta
        }

        
    },

    actions: {
        getAuth(state) {
            return state.isAuth
        }
    },
    modules: {
        auth: auth,
        escala: escala,
    }
})

export default store