<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
        
        <img src="../assets/logo.png" alt="Dashboard" @click="toDashboard">
        <b-navbar-brand class="nome-empresa" href="#" @click="toDashboard">Finances Plus</b-navbar-brand>
        <!-- <b-navbar-brand class="nome-empresa" to="/dashboard" href="#">Finances Plus</b-navbar-brand> -->
        <!-- <router-link to="/dashboard" exact class="nome-empresa">EventoSP</router-link> -->

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>      


            <b-nav-item-dropdown :text="`${sedeNome}`" class="active item-drop" right>
                <b-dropdown-item href="#" @click="setSede(sede)" v-for="sede in sedes" :key="sede.id">{{sede.nome}}</b-dropdown-item>
            </b-nav-item-dropdown>
            
            <b-nav-item-dropdown :text="`${contaNome}`" class="active item-drop" right v-if="menuContasvisivel">
                <b-dropdown-item href="#" @click="setConta(conta)" v-for="conta in contas" :key="conta.id">{{conta.nome}}</b-dropdown-item>
            </b-nav-item-dropdown>
    

            <b-nav-item-dropdown text="Cadastros" class="item-drop" :class="{active: true}" right v-if="menuVisivel">
                <!-- <router-link to="/criar-escala-1" tag="b-dropdown-item" exact>Nova Escala</router-link> -->
                <b-dropdown-item href="#" @click="toContas()">Contas</b-dropdown-item>
                <b-dropdown-item href="#" @click="toFornecedores()">Fornecedores</b-dropdown-item>
                <b-dropdown-item href="#" @click="toPagadores()">Pagadores</b-dropdown-item>
                <b-dropdown-item href="#" @click="toCategorias()">Categorias</b-dropdown-item>
                <!-- <b-dropdown-item href="#" @click="toFornecedores()">Fornecedores</b-dropdown-item> -->
            </b-nav-item-dropdown>    
 


            <b-nav-item-dropdown text="Relatórios" class="item-drop" :class="{active: true}" right v-if="menuVisivel">
                <b-dropdown-item href="#" @click="toRelConsolidadoCategorias()">Consolidado por Categorias</b-dropdown-item>
                <b-dropdown-item href="#" @click="toRelConsolidadoDetalhes()">Consolidado Detalhado</b-dropdown-item>
            </b-nav-item-dropdown>   



            <b-button-group v-if="menuVisivel">
                <b-dropdown right split variant="success" text="Lançamentos" @click="toLancamentos()">
                    <b-dropdown-item @click="showModalTranferencia()">Transferências</b-dropdown-item>
                    <b-dropdown-item @click="toFechamentoMensal()">Fechamento de caixa</b-dropdown-item>
                    <!-- <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item>Item 3</b-dropdown-item> -->
                </b-dropdown>
            </b-button-group>


            <!-- <b-nav-item href="#" class="item-drop active" @click="showModalTranferencia()" v-if="menuContasvisivel">Transferências</b-nav-item> -->
            <!-- <b-nav-item href="#" class="item-drop active" @click="toLancamentos()" v-if="menuVisivel">Lançamentos</b-nav-item> -->
       



        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown class="active" right>
                <!-- Using 'button-content' slot -->
                <template #button-content>
                <em>{{userName}}</em>
                </template>
                <b-dropdown-item @click="gerenciarUsuarios" v-if="menusAdminsShow">Gerenciar Usuários</b-dropdown-item>
                <b-dropdown-item @click="alterarSenha">Alterar Senha</b-dropdown-item>
                <b-dropdown-item href="#" @click="signOut">Sair</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>


    <div>
        <b-modal ref="modal-transferencia" hide-footer title="Tranferência entre contas" size="lg">
        <div class="row text-left col-lg-12">


            <div class="form-group col-lg-6">
                <label><span class="text-danger"></span> Conta origem</label>
                <b-form-select
                v-model="form.contaOrigem"
                :options="contasTranfer"
                ></b-form-select>
            </div>
        
            <div class="form-group col-lg-6">
                <label><span class="text-danger"></span> Conta destino</label>
                <b-form-select
                v-model="form.contaDestino"
                :options="contasTranfer"
                ></b-form-select>
            </div>


          <div class="form-group col-lg-6">
            <label><span class="text-danger"></span> Forma de pagamento</label>
            <b-form-select
              v-model="form.formaPagto"
              :options="formaPagtos"
            ></b-form-select>
          </div>
          
          

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Data tranferência</label>
            <b-form-input
              type="date"
              id="datepicker-dateformat2"
              v-model="form.vencimento"
              locale="pt"
            ></b-form-input>
          </div>


          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Valor</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.valor"
              placeholder="0,00"
              @keyup="formatMoeda($event)"
              :readonly="camposLeitura"
            ></b-form-input>
          </div>


        </div>
        <b-button class="mt-3" variant="outline-success" block @click="tranferir"> Confirmar</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click="hideModalTranferencia"> Cancelar</b-button>

      </b-modal>    
    </div>


  </div>
</template>

<script>
import api from "@/services/api";
import { msg, acessoRestrito } from "@/global";
import dayjs from 'dayjs'
export default {
    data(){
        return{

            menusAdminsShow: false,
            userName: '',
            menuContasvisivel: false,
            menuVisivel: false,

            sedes: [],
            contas: [],
            sedeNome: 'Selecione a sede',
            contaNome: 'Selecione a conta',


            form: {
                contaOrigem: null,
                formaPagto: "Dinheiro",
                vencimento: dayjs().format('YYYY-MM-DD')
            },

            contasTranfer: [],

            formaPagtos: [
                "Dinheiro",
                "Cheque",
                "Cartão Débito",
                "Cartão Crédito",
                "Tranferência",
                "DOC",
                "TED",
                "Pix",
            ],

        }
    },

    created() {  

        this.getSedes()
        this.getContas()

        this.contasTranfer = this.contas.map(item => {
            return {
                value: item.id,
                text: item.nome
            }
        })

        this.form.contaOrigem = this.$store.state.conta.id
    },

    methods: {

        showModalTranferencia() {
            acessoRestrito(1050)
            this.$refs["modal-transferencia"].show();
        },

        hideModalTranferencia() {
            this.$refs["modal-transferencia"].hide();
        },

        signOut() {
            // console.log('swdwde')
            localStorage.removeItem('user')
            localStorage.setItem('path', '/neutro')

            this.$store.commit('auth/toggleMenu', false)
            this.$store.commit('auth/setUser', null)
            this.$store.commit("setSedes", null);
            // this.$store.commit("setContas", null);

            this.$router.push({path: '/login'})
        },

        getSedes() {
            
            this.sedes = this.$store.state.sedes  

            if (this.sedes.length == 1) {
                this.setSede(this.sedes[0])
            }
        },

        async setSede(sede){
            this.sedeNome = sede.nome
            this.$store.commit("setSede", sede);
            this.menuContasvisivel =  true

            const user = this.$store.state.auth.user 
            const contas = user.contas.filter(item => item.idSede == sede.id)
            // console.log('contas',contas)
            this.$store.commit("setContas", contas); 
            this.getContas()


            // if (this.sedes.length > 1) {
            //     this.$router.push({ name: 'home' })
            //     await new Promise(r => setTimeout(r, 50));
            //     // this.$router.push({ name: 'dashboard' })
            //     this.$router.push({ name: 'dashboardContas' })
            // }

        },


        async getContas() {
            
            this.contas = this.$store.state.contas  
            this.menuVisivel = false 
            this.contaNome = 'Selecione a conta'
            
            if (this.contas.length == 1) {
                this.setConta(this.contas[0])
            }

            if (this.contas.length > 1) {
                this.$router.push({ name: 'home' })
                await new Promise(r => setTimeout(r, 50));
                this.$router.push({ name: 'dashboardContas' })
            } else {
                this.$router.push({ name: 'home' })
                await new Promise(r => setTimeout(r, 50));
                this.$router.push({ name: 'dashboard' })
            }

        },

        async setConta(conta){
            this.contaNome = conta.nome
            this.$store.commit("setConta", conta);
            this.menuVisivel = true 
            this.contas.length == 1 ? this.menuContasvisivel = false : this.menuContasvisivel = true 

            // if (this.sedes.length > 1) this.$router.push({ name: 'dashboard' })
        
            // if (this.sedes.length > 1) {
                this.$router.push({ name: 'home' })
                await new Promise(r => setTimeout(r, 50));
                this.$router.push({ name: 'dashboard' })
            // }

        },







        getUserNome() {
            const authLocal = localStorage.getItem('user')
            const auth = JSON.parse(authLocal)
            this.userName = auth.nome

            if (auth.email == 'kepas7@gmail.com') {
                this.menusAdminsShow = true
            }
        },


        async toDashboard() {

            if (this.sedes.length > 1) {
                this.$router.push({ name: 'home' })
                await new Promise(r => setTimeout(r, 50));
                this.$router.push({ name: 'dashboardContas' })
            } else {
                this.$router.push({ name: 'home' })
                await new Promise(r => setTimeout(r, 50));
                this.$router.push({ name: 'dashboard' })
            }
        },

        
        toContas() {
            acessoRestrito(1000)
            this.$router.push({ name: 'Contas' })   
        },

        toFornecedores() {
            acessoRestrito(1010)
            this.$router.push({ name: 'Fornecedores' })   
        },

        toPagadores() {
            acessoRestrito(1020)
            this.$router.push({ name: 'Pagadores' })   
        },

        toCategorias() {
            acessoRestrito(1030)
            this.$router.push({ name: 'Categorias' })   
        },

        toLancamentos() {
            acessoRestrito(1060)
            this.$router.push({ name: 'Lancamentos' })   
        },

        toFechamentoMensal() {
            acessoRestrito(1060)
            this.$router.push({ name: 'FechamentoMensal' })   
        },

        toRelConsolidadoCategorias() {
            // acessoRestrito(1060)
            this.$router.push({ name: 'RelConsolidadoCategorias' }) 
        },

        toRelConsolidadoDetalhes() {
            // acessoRestrito(1060)
            this.$router.push({ name: 'RelConsolidadoDetalhado' }) 
        },

        alterarSenha() {
            this.$router.push({ name: 'alterarSenha' })   
        },

        gerenciarUsuarios() {
            this.$router.push({ name: 'gerenciarUsuarios' }) 
        },



        formatMoeda(event) {
      
            // console.log(event.target.value)
            let input = event.target

            var valor = input.value.replace(/,/, '')

            if (valor.length > 2)
                input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
            else   
            if (valor.length > 0 && valor.length < 4)
                input.value = valor
            else   
                input.value = ''

        },


        tranferir() {

            this.form.idSede = this.$store.state.sede.id
            
            api.post("transferencia", this.form)
            .then(() => {
                this.hideModalTranferencia();
                msg('TRANSFERÊNCIA CONCLUIDA COM SUCESSO.', "verde", 5000);

                this.form = {
                    contaOrigem: null,
                    formaPagto: "Dinheiro",
                    vencimento: dayjs().format('YYYY-MM-DD')
                }
                
            })
            .catch((err) => {
                msg(err.response.data, "vermelho", 5000);
            });

        },


    },

    mounted() {
        this.getUserNome()  
    }

};
</script>

<style scoped>

    img {
        height: 40px;
        margin-right: 7px;
        cursor: pointer;
    }

    /* .nome-empresa {
        font-weight: bold;
        margin-right: 50px;
        cursor: pointer;
    } */

    .nome-empresa {
        font-weight: bold;
        font-size: 20px;
        color: white;
        /* padding-top: 10px; */
        /* margin-top: 10px; */
        margin-right: 50px;
        cursor: pointer;
        text-decoration: none;
    }

    .item-drop {
        margin-right: 20px;
        /* font-size: 23; */
    }

    .nav-link-sel {
        font-weight: bold;
    }

    .sel-conta {
        margin-right: 50px;
        font-size: 50px;
    }



</style>