<template> 
    <div>  
      
  
      <div class="text-center text-warning my-2" v-if="load">
          <b-spinner class="align-middle"></b-spinner>
          <strong></strong>
      </div>
      
      <div class="row col-lg-12" v-else>
  
  
        <div class="form-group col-lg-6">
          <b-form-group label="Categoria" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
            <b-input-group>
            <b-form-select
                v-model="filtro.idCategoria"
                :options="categorias"
                @change="getItensCategorias"
            ></b-form-select>
            </b-input-group>
          </b-form-group>
        </div> 

        <div class="form-group col-lg-6">
          <b-form-group label="Itens" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
            <b-input-group>
            <b-form-select
                v-model="item"
                :options="itens"
            ></b-form-select>
            </b-input-group>
          </b-form-group>
        </div> 

        <div class="linha"></div>

        <div class="form-group col-lg-6">
          <b-form-group label="Fornecedor ou Pagador" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
            <b-input-group>
            <b-form-select
                v-model="filtro.labelFornecedor"
                :options="opcoesFornecedor"
                @change="changeForncedoresOuPagadores"
            ></b-form-select>
            </b-input-group>
          </b-form-group>
        </div> 


        <div class="form-group col-lg-6">
          <b-form-group :label="filtro.labelFornecedor" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
            <b-input-group>
            <b-form-select
                v-model="filtro.fornecedor"
                :options="fornecedoresOuPagadores"
            ></b-form-select>
            </b-input-group>
          </b-form-group>
        </div> 


        <div class="linha"></div>


        <div class="form-group col-lg-6">
          <b-form-group label="Situação" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
            <b-input-group>
            <b-form-select
                v-model="filtro.situacao"
                :options="situacoes"
            ></b-form-select>
            </b-input-group>
          </b-form-group>
        </div> 

        <div class="form-group col-lg-6">
          <b-form-group label="Modalidade" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
            <b-input-group>
            <b-form-select
                v-model="filtro.modalidade"
                :options="opcoesModalidade"
            ></b-form-select>
            </b-input-group>
          </b-form-group>
        </div> 


        <div class="linha mb-3"></div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Data inicial</label>
          <b-form-input
            type="date"
            id="datepicker-dateformat2"
            v-model="filtro.dataInicio"
            locale="pt"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Data final</label>
          <b-form-input
            type="date"
            id="datepicker-dateformat2"
            v-model="filtro.dataFinal"
            locale="pt"
          ></b-form-input>
        </div>



        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Valor inicial</label>
          <b-form-input
            id="input-1"
            class="text-uppercase"
            v-model="filtro.valorInicial"
            placeholder="0,00"
            @keyup="formatMoeda($event)"
            :readonly="camposLeitura"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Valor final</label>
          <b-form-input
            id="input-1"
            class="text-uppercase"
            v-model="filtro.valorFinal"
            placeholder="0,00"
            @keyup="formatMoeda($event)"
            :readonly="camposLeitura"
          ></b-form-input>
        </div>

        
  
        <!-- <b-card
          class="mt-3"
            header="Resultado">
          <pre class="m-0">{{ hospede }}</pre>
        </b-card> -->
  
          
  
        <b-container class="bv-example-row">
          <b-row align-h="start">
            <b-button class="mt-3 botao-confirmar2" variant="success" @click="filtrar()">Filtrar</b-button>
            <b-button class="mt-3 botao-confirmar2" variant="danger" @click="cancelar()">Cancelar</b-button>
          </b-row>
        </b-container>

  
      </div>
  

    </div> 
  </template>
   
  <script>
  import api from "@/services/api";
    
  export default {
    name: 'ModalFiltroAvancado', 
    props: {
      fornecedores: [],
      pagadores: [],
      categorias: [],
      itensTodos: [],
    },
  
    data() {
      return {  
  
        load: false,

        filtro: {
          idConta: null,
          labelFornecedor: '~',
          fornecedor: null,
          idCategoria: null,
          idItem: null,
          situacao: 'TODAS',
          modalidade: 'TODAS',
          dataInicio: null,
          dataFinal: null,
          valorInicial: null,
          valorFinal: null
        },
  

        opcoesFornecedor: ['Fornecedores','Pagadores'],
        // labelFornecedor: '~',

        fornecedoresOuPagadores: [],
        // fornecedor: null,

        itens: [],
        // idCategoria: 'null',
        // item: 'null',
        
        situacoes: ['TODAS','ABERTOS','VENCIDOS','PAGOS','TRANSFERÊNCIAS'],
        situacao: 'TODAS',

        opcoesModalidade: ['TODAS','DESPESAS','RECEITAS'],
        modalidade: 'TODAS',
  
  
      }
  
    },
    created() { 
  
      // console.log(this.reserva)
      //   this.getVeiculos()
    },
    
    methods: {
  
      msgNotification(texto, tipo, tempo) {
          this.$toast.open({
              message: texto,
              type: tipo,
              position: 'top-right',
              duration: tempo
              // all of other options may go here
          })
      },

      formatMoeda(event) {
      
        // console.log(event.target.value)
        let input = event.target

        var valor = input.value.replace(/,/, '')

        if (valor.length > 2)
            input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
        else   
        if (valor.length > 0 && valor.length < 4)
            input.value = valor
        else   
            input.value = ''

      },


      getItensCategorias() {

        // console.log(this.idCategoria, this.itensTodos)

        this.itens = this.itensTodos
        .filter(item => item.idCategoria == this.filtro.idCategoria)
        .map(item => {
          return {
            value: item.id,
            text: item.nome
          }
        })

        this.filtro.idItem = null
      },

      changeForncedoresOuPagadores() {

        // console.log(this.labelFornecedor)
        this.fornecedoresOuPagadores = []
        this.fornecedoresOuPagadores = this.filtro.labelFornecedor == 'Fornecedores' ? this.fornecedores : this.pagadores
      },

      filtrar() {

        this.load = true
        this.filtro.idConta = this.$store.state.conta.id

        api.patch(`lancamentos-filtrar`, {filtro: this.filtro})
        .then((res) => {

          this.load = false
          const lancamentos = res.data
          // console.log(lancamentos)

          this.$emit('EMIT_filtrarLancamentos', lancamentos)
        })
        .catch((err) => {
          this.load = false
          this.msgNotification(err.response.data, 'error', 5000)
        });

      },

      cancelar() {

        this.$emit('EMIT_fechar_modal')
      }
  
    }
  
      
  };
  
  </script>
   
     
   <style scoped>
  
  
   .tabela {
      width: 80vw;
      margin-top: 0px;
    }
  
  
  
    .botao-confirmar2 {
      width: 170px; 
      margin-left: 10px;
      margin-bottom: 15px;
    }
  
    .cursor { 
      cursor: pointer;
    }

    .linha {
      width: 100%;
      height: 1px;
      background-color: #17a2b8;
    }
  
  </style>