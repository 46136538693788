<template>
  <div id="page-login">
    <aside>
      <div class="fundo-aside"></div>
    </aside>

    <main>
      <div class="loginArea" v-if="login">
        <div class="logo">
          <img src="../../assets/logo.png" alt="" />
          <span id="logo-texto1">Finances </span>
          <span id="logo-texto2">Plus</span>
        </div>
        <!-- <span>Bem-vindo de volta!</span> -->
        <form @submit.prevent="signIn">
          <input type="text" placeholder="email@email.com" v-model="email" />
          <input type="password" placeholder="sua senha..." v-model="senha" />
          <button type="submit" :class="{disabled: validating}">{{textLogin}}</button>
        </form>
        <a @click="toggleTelaLogin">Esqueci minha senha</a>
      </div>

      <div class="loginArea" v-else>
        <h1>Enviar senha</h1>
        <form @submit.prevent="handreRegister">
          <input type="text" placeholder="email@emai.com" v-model="email" />
          <button @click="esqueciMinhaSenha">Enviar e-mail</button>
        </form>
        <a @click="toggleTelaLogin">Lembrei da senha</a>
      </div>
    </main>
  </div>
</template>




<script>
import api from "../../services/api";
import { msg } from '@/global'

export default {
  name: "Login",
  data() {
    return {
      nome: "",
      email: "",
      senha: "",
      validating: false,
      login: true,
      textLogin: 'Acessar'
    };
  },
  methods: {

    toggleTelaLogin() {
        // this.$store.commit("toggleMenu", false);
        this.$store.commit('auth/toggleMenu', false)

        this.login = !this.login;
        this.nome = "";
        this.email = "";
        this.password = "";
    },

    async signIn() {

        // this.$store.commit("auth/setLoading", true);
        this.textLogin = 'Autenticando...'
        this.validating = true

        const user = {
            email: this.email,
            senha: this.senha,
        };



        api.post("signin", user)
        .then((res) => {

            localStorage.setItem("user", JSON.stringify(res.data));
            this.$store.commit("auth/setUser", res.data);
            this.$store.commit("setSedes", res.data.sedes);
            this.$store.commit("setContas", res.data.contas);

            // console.log('aaaaaaaaaaaaaa',res.data.sedes,'aa')

            this.$store.commit("auth/toggleMenu", true);
            localStorage.setItem('path', '')
            this.$store.commit("auth/setLoading", false);

            if (res.data.contas.length > 1) {
              this.$router.push({ name: "dashboardContas" })
            } else {
              this.$router.push({ name: "dashboard" })
            }
            
        })
        .catch((error) => {
            // console.log("eerororor", error.data);
            this.$store.commit("auth/setLoading", false);
            this.textLogin = 'Acessar'  
            this.validating = false
             msg(error.response.data, 'vermelho', 3000)
            return;
        }); 

    },


    esqueciMinhaSenha() {

      if (!this.email) {
        msg('INFORME O E-MAIL', 'vermelho', 5000)
        return
      }

      api.put("signforgot", { email: this.email })
      .then(() => {
        // console.log(res.data);
        msg('NOVA SENHA ENVIADA!', 'verde', 5000)
        // this.$router.replace({ name: "dashboard" });
      })
      .catch(err => msg(err.response.data, 'vermelho', 3000))

    }



  },

};
</script>




<style scoped>
#page-login {
  /* background: #7159c1; */
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 100vh;
}

aside {
  /* background: #17a2b8; */
  background: url("../../assets/fundo.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.fundo-aside {
  height: 100%;
  width: 100%;
  background: #17a2b8;
  opacity: 0.8;
}

main {
  /* background: cyan; */
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.tela {
  height: 300px;
  width: 300px;
  background: darkgoldenrod;
}

.loginArea {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 100px;
  max-width: 600px;
  width: 420px;
  height: 330px;
}

form {
  display: flex;
  flex-direction: column;
  margin: 0px 25px;
}

input {
  margin-bottom: 15px;
  height: 40px;
  width: 300px;
  font-size: 18px;
  padding: 10px;
  outline: none;
  border: 0;
  border-radius: 9px;
  background: rgb(241, 241, 241);
}

button {
  margin-top: 10px;
  height: 40px;
  border: 0;
  border-radius: 50px;
  background: #17a2b8;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.loginArea a {
  margin-top: 10px;
  text-decoration: none;
  color: #17a2b8;
  font-size: 15px;
  cursor: pointer;
}

img {
  height: 60px;
  margin-top: -14px;
  margin-right: 5px;
}

#logo-texto1 {
  font-size: 32px;
  font-weight: bold;
  padding-top: 15px;
}

.logo {
  margin-bottom: auto;
  margin-top: auto;
}

#logo-texto2 {
  font-size: 32px;
  font-weight: 500;
}

</style>
