<template>
  <div id="page-contas">
    <b-list-group>
      <div>
        <h2>Gerenciar usuários</h2>

        <!-- <div class="form-row  bv-example-row-flex-cols"> -->
        <!-- <b-container class="bv-example-row"> -->

            <b-row align-h="between">
                
                <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button>

                <div class="form-group col-lg-4">
                    <label><span class="text-danger"></span> Buscar</label>
                    <input class="form-control"  v-model="filtro" />
                </div>

            </b-row>

        <!-- </b-container> -->
        <!-- </div> -->
      </div>

      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="items"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>


          <template #cell(Acessos)="data">     
            <b-icon class="cursor" icon="shield-lock" variant="success" style="width: 23px; height: 23px;" @click="showAcessos(data.item)"></b-icon>
          </template>



          <template #cell(Editar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../../assets/edit1.png"
              alt="editar"
              height="23px"
              @click="Editar(data.item)"
            />
          </template>
          <template #cell(Apagar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="showModalApagar(data.item)"
            />
          </template>
        </b-table>
      </div>
    </b-list-group>

    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Você confirma a exclusão?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="hideModalApagar"
          >Não</b-button
        >
      </b-modal>
    </div>

    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Usuário" size="lg">
        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span> Nome</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.nome"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span> E-mail</label>
            <b-form-input
              id="input-1"
              class="text-lowercase"
              v-model="form.email"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

          <div class="col-lg-12">
            <div class="form-row">
              <b-form-group class="mx-3" label><span class="tecnico">Selecione as sedes de acesso</span>
                <b-form-checkbox-group id="checkbox-group-1" name="conhecTec" v-model="form.sedesAccess" :options="listaSedes" switches stacked></b-form-checkbox-group>
              </b-form-group>
              <div class="risco"></div>
            </div>
          </div>


          <!-- <b-card
            class="mt-3"
            header="Resultado Vacinação"
          >
          <pre class="m-0">{{ form.sedes }}</pre>
      
          </b-card> -->

        </div>

        <b-button class="mt-3" variant="outline-success" block @click="save">Confirmar</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click="hideModalAdd">Cancelar</b-button>
      </b-modal>
    </div>

    

    <!-- MODAL ACCESS -->
    <div>
      <b-modal ref="modal-acesso" hide-footer title="Permissões de acessos" size="lg">
        <div class="row text-left col-lg-12">

          <div class="col-lg-12">
            <div class="form-row">
              <b-form-group class="mx-3" label>
                <b-form-checkbox-group id="checkbox-group-1" name="conhecTec" v-model="form.accessRules" :options="listaAccess" switches stacked></b-form-checkbox-group>
              </b-form-group>
              <div class="risco"></div>
            </div>
          </div>

        </div>

        <b-button class="mt-3" variant="outline-success" block @click="saveAccess">Confirmar</b-button>
        <b-button class="mt-2" variant="outline-danger" block @click="$refs['modal-acesso'].hide()">Cancelar</b-button>
      </b-modal>
    </div>




  </div>
</template>

<script>
import api from "../../services/api";
import { msg, acessoRestrito } from "@/global";

export default {

  data() {
    return {
      dadosHeader: {},
      filtro: '',

      form: {},

      fields: [
        { key: "id", label: "ID" },
        { key: 'nome', label: 'Nome' },
        { key: 'email', label: 'E-mail / Login' },
        { key: "Acessos", label: "Permissões", thClass: 'text-center', tdClass: 'text-center' },
        { key: "Editar", label: "Editar / Sedes", thClass: 'text-center', tdClass: 'text-center' },
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],

      items: [], 
      listaSedes: [],

      listaAccess: [],


      load: true,
      perPage: 10,
      currentPage: 1,
    };
  },

  created() {
    this.getUsuarios();
  },

  methods: {

    showModalApagar(item) {
      acessoRestrito(9003)

      this.$refs["modal-del"].show();
      this.form = item;
    },
    hideModalApagar() {
      this.$refs["modal-del"].hide();
    },

    showModalAdd() {
      acessoRestrito(9001)
      this.$refs["modal-add"].show();
    },
    hideModalAdd() {
      this.$refs["modal-add"].hide();
    },

    showAcessos(user) {
      acessoRestrito(9004)
      this.form = user;
      this.$refs["modal-acesso"].show();
    },

    formatMoeda(event) {
      
        // console.log(event.target.value)
        let input = event.target

        var valor = input.value.replace(/,/, '')

        if (valor.length > 2)
            input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
        else   
        if (valor.length > 0 && valor.length < 3)
            input.value = valor
        else   
            input.value = ''

    },

    getUsuarios() {
      acessoRestrito(9000)
      this.load = true;

      api
        .get('admin/usuarios')
        .then((res) => {
          this.load = false;
          this.items = res.data.usuarios;
          this.listaSedes = res.data.listaSedes;
          this.listaAccess = res.data.listaAccess;
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },

    apagar() {

      this.$refs["modal-del"].hide();

      api
        .delete(`admin/usuarios/${this.form.id}`)
        .then(() => {
          msg("USUÁRIO REMOVIDO COM SUCESSO.", "verde", 3000);
          this.getUsuarios();
          this.form = {};
        })
        .catch((err) => msg(err.response.data, "vermelho", 3000));
    },

    Editar(item) {
      acessoRestrito(9002)
      this.form = item;
      this.showModalAdd();
    },

    save() {

      api
        .post("admin/usuarios", this.form)
        .then((res) => {
          this.hideModalAdd();
          msg(res.data, "verde", 5000);
          this.getUsuarios();
          this.form = {};
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },

    saveAccess() {

      api
        .put("admin/usuarios/permissoes", this.form)
        .then(() => {
          this.$refs["modal-acesso"].hide();
          msg('Permissões alteradas.', "verde", 5000);
          this.getUsuarios();
          this.form = {};
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },


  },

    computed: {

        filtrar() {

            // console.log('opaa')
            
            if (this.filtro.trim() == '') return this.items
                
            let listaFiltrada = []
            this.items.forEach((item) => {
                if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
                if(item.saldo && item.saldo.toString().match(this.filtro)) listaFiltrada.push(item) 
            })

            return listaFiltrada
        },
    },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */

.btnRight {
  /* float: right; */
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  /* font-size: 18px; */
  font-weight: bold;
}

#page-contas h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

.cursor {
  cursor: pointer; 
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.text-dados {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.75rem;
}

@media screen and (max-width: 480px) {
    .text-dados {
        display: block;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
        font-size: 24px;
        /* font-size: 1.75rem; */
    }
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

}
</style>