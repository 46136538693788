import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../pages/Home'
import Dashboard from '../pages/Dashboard'
import DashboardContas from '../pages/DashboardContas'
import GerenciarUsuarios from '../pages/admin/GerenciarUsuarios'
import Login from '../pages/auth/Login'
import AlterarSenha from '../pages/auth/AlterarSenha'
import Contas from '../pages/Contas'
import Fornecedores from '../pages/Fornecedores'
import Pagadores from '../pages/Pagadores'
import Categorias from '../pages/Categorias'
import Lancamentos from '../pages/lancamentos/Lancamentos'
import FechamentoMensal from '../pages/FechamentoMensal'
import ConsolidadoCategorias from '../pages/relatorios/ConsolidadoCategorias'
import ConsolidadoDetalhado from '../pages/relatorios/ConsolidadoDetalhado'

Vue.use(VueRouter)


const routes = [
    { name: 'home', path: '/home', component: Home,  meta: { requiresAuth: true } },
    { name: 'dashboard', path: '/dashboard', component: Dashboard,  meta: { requiresAuth: true } },
    { name: 'dashboardContas', path: '/dashboard-contas', component: DashboardContas,  meta: { requiresAuth: true } },
    { name: 'gerenciarUsuarios', path: '/gerenciar-usuarios', component: GerenciarUsuarios },
    { name: 'auth', path: '/login', component: Login },
    { name: 'alterarSenha', path: '/alterar-senha', component: AlterarSenha },
    { name: 'Contas', path: '/contas', component: Contas, props: true, meta: { requiresAuth: true } },
    { name: 'Fornecedores', path: '/fornecedores', component: Fornecedores, props: true, meta: { requiresAuth: true } },
    { name: 'Pagadores', path: '/pagadores', component: Pagadores, props: true, meta: { requiresAuth: true } },
    { name: 'Categorias', path: '/categorias', component: Categorias, props: true, meta: { requiresAuth: true } },
    { name: 'Lancamentos', path: '/lancamentos', component: Lancamentos, props: true, meta: { requiresAuth: true } },
    { name: 'FechamentoMensal', path: '/fechamento-mensal', component: FechamentoMensal, props: true, meta: { requiresAuth: true } },
    { name: 'RelConsolidadoCategorias', path: '/relatorios/consolidado-categorias', component: ConsolidadoCategorias, meta: { requiresAuth: true } },
    { name: 'RelConsolidadoDetalhado', path: '/relatorios/consolidado-detalhado', component: ConsolidadoDetalhado, meta: { requiresAuth: true } },
]





const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})


// router.beforeEach((to, from, next) => {

    // const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    // const auth = store.state.auth.isMenuVisible 

    // console.log(requiresAuth, auth)

    // if(requiresAuth && !auth) {
    //     next('/login')
    // } else {
    //     next()
    // }





    // const pathAnterior = localStorage.getItem('path')

    // // console.log("pathAnterior:",pathAnterior,"  estou aqui:",from.path,'   indo para:',to.path)

    // if (pathAnterior == '/neutro') {
    //     localStorage.setItem('path', '/neutro')
    //     next()  
    // } else
    // if (pathAnterior == to.path) {
    //     // console.log('negado')
    //     // console.log('---------------------------------')
    //     next(false)
    // } else {
    //     // console.log('Permitido')
    //     // console.log('---------------------------------')
    //     localStorage.setItem('path', from.path)
    //     next()
    // }
// })





// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
//     console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')

//     console.log('dddddddddd', mapState(['isAuth']))
    
//     const isAuth =  

//     console.log('vbbbbbbbbbbbbbbbbbbbbbb', isAuth)

//     if (requiresAuth && !isAuth) {
//         next({ name:'auth' })   
//         // next()
//     }else{
//         next()
//     }
// })

export default router