<template>
  <div id="page-fechamentos">
    <b-list-group>
      <div>
        <h2>Fechamentos mensais</h2>

        <!-- <div class="form-row  bv-example-row-flex-cols"> -->
        <!-- <b-container class="bv-example-row"> -->

            <b-row align-h="between">
                
                <b-button class="btnRight" variant="btn btn-success btn-sg" @click="showModalAdd" >Informar fechamento</b-button>

                <div class="form-group col-lg-4">
                    <label><span class="text-danger"></span> Buscar</label>
                    <input class="form-control"  v-model="filtro" />
                </div>

            </b-row>

        <!-- </b-container> -->
        <!-- </div> -->
      </div>

      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="filtrar"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>


          <template #cell(desbloquear)="data">
              <b-icon icon="unlock-fill" variant="success" class="cursor" @click="showModalDesbloquear(data.item)" v-if="data.item.status == 'fechado'"></b-icon>
          </template>

        </b-table>
      </div>
    </b-list-group>


    <!-- MODAL DESBLOQUEIO -->
    <div>
      <b-modal ref="modal-des" hide-footer title="Desbloquear o fechamento?">
        <!-- <div class="d-block text-center">
          <h3>Desbloquear o fechamento?</h3>
        </div> -->

          <div class="form-group col-lg-12">
            <label><span class="text-danger">*</span>Motivo do desbloqueio</label>
            <b-form-textarea v-model="motivoDesbloqueio" 
            rows="8" 
            no-resize
            :state="motivoDesbloqueio.length >= 5"
            placeholder="Digite minimo de 5 caracteres."
            ></b-form-textarea>
          </div>

        <b-button class="mt-3" variant="outline-success" block @click="desbloquear">Sim</b-button>
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-des'].hide()"
          >Não</b-button
        >

      </b-modal>
    </div>

    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Fechamento" size="lg">
        <div class="row text-left col-lg-12">


            <div class="form-group col-lg-6">
                <label><span class="text-danger"></span> Conta</label>
                <b-input-group>
                    <b-form-select
                    id='contas'
                    v-model="conta"
                    :options="contas"
                    ></b-form-select>
                </b-input-group>
            </div>
        

            <div class="form-group col-lg-6 sel-datas">
                <!-- <h2>Plantões</h2> -->
                <label class="texto-data">Data de referência</label>
                <b-form-select id='sel-mes' v-model="selectedMes" :options="meses" @change="getLancamentos()"></b-form-select>
                <b-form-spinbutton class="sel-ano" v-model="selectedAno" min="2015" max="2040" @change="getLancamentos()"></b-form-spinbutton>
            </div>


        </div>
        <b-button class="mt-3" variant="outline-success" block @click="save"
          >Confirmar</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-add'].hide()"
          >Cancelar</b-button
        >
      </b-modal>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { msg } from "@/global";
import dayjs from "dayjs"

export default {
  name: "Pagadores",

  data() {
    return {
        dadosHeader: {},
        filtro: '',

        form: {},
        idFechamento: null,

        fields: [
            { key: "nomeConta", label: "Conta" },
            { key: "anoMesRef", label: "Data Ref." },
            { key: "userCreate", label: "Usuário" },
            { key: "dataCreate", label: "Fechamento" },
            { key: 'saldo', label: 'Saldo total', thClass: 'text-right', tdClass: 'text-right'},
            { key: "status", label: "Status", thClass: 'text-right', tdClass: 'text-right'},
            { key: "desbloquear", label: "Desbloquear", thClass: 'text-center', tdClass: 'text-center' },
        ],

        items: [], 


        selectedMes: null,
        meses: [
            { value: '01', text: 'Janeiro' },
            { value: '02', text: 'Fevereiro' },
            { value: '03', text: 'Março' },
            { value: '04', text: 'Abril' },
            { value: '05', text: 'Maio' },
            { value: '06', text: 'Junho' },
            { value: '07', text: 'Julho' },
            { value: '08', text: 'Agosto' },
            { value: '09', text: 'Setembro' },
            { value: '10', text: 'Outubro' },
            { value: '11', text: 'Novembro' },
            { value: '12', text: 'Dezembro' },
        ],

        selectedAno: null,


        contas: [],
        motivoDesbloqueio: '',

        load: true,
        perPage: 10,
        currentPage: 1,
    };
  },

  created() {

        this.selectedMes = dayjs().subtract(1, 'month').format('MM')
        this.selectedAno = Number(dayjs().format('YYYY'))

        this.getContas();
        this.getFechamentos();
  },

  methods: {

    msgNotification(texto, tipo, tempo) {
        this.$toast.open({
            message: texto,
            type: tipo,
            position: 'top-right',
            duration: tempo
        })
    },

    showModalDesbloquear(item) {
      this.$refs["modal-des"].show();
      this.idFechamento = item.id;
    },

    showModalAdd() {
      this.form = {}
      this.$refs["modal-add"].show()
    },



    getContas() {

        const idSede = this.$store.state.sede.id

        api.get(`relatorios/contas/${idSede}`)
        .then((res) => {
            // console.log('resssssss', res.data)
            this.contas = res.data.filter(item => item.text != 'TODAS')
        })
        .catch((err) => {
            this.load = false;
            this.msgNotification(err, 'error', 3000)
        });
        
    },

    getFechamentos() {
      this.load = true;
      const idSede = this.$store.state.sede.id 

      api
        .get(`fechamentos/${idSede}`)
        .then((res) => {
          this.load = false;
          this.items = res.data;
        //   console.log('oooooooooooo',res.data)
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },


    async save() {

        if (!this.conta) {
            this.msgNotification('Informe a conta para o fechamento.', 'warning', 3000) 
            return
        }

        this.form.idSede = this.$store.state.sede.id
        this.form.idConta = this.conta
        this.form.anoMesRef = this.selectedAno +'-'+ this.selectedMes
        
        try {
            await api.post(`fechamentos`, this.form)
            this.$refs["modal-add"].hide();
            this.msgNotification('Fechamento informado com sucesso.', 'success', 3000) 
            this.getFechamentos()

        } catch (error) {
            // console.log('aaaaaaaa',error)
            this.msgNotification(error.response.data, 'warning', 3000)
        }
        
    },
    

    desbloquear() {

        this.$refs["modal-des"].hide();

        api.put(`fechamentos`, { idFechamento: this.idFechamento, motivoDesbloqueio: this.motivoDesbloqueio })
        .then(() => {
            this.msgNotification('Fechamento deabloqueado com sucesso.', 'success', 3000) 
            this.getFechamentos()
        })
        .catch((err) => {
          this.$refs["modal-des"].show();
          this.msgNotification(err.response.data, 'warning', 3000)
        });
    },


  },

    computed: {

        filtrar() {

            // console.log('opaa')
            const busca = this.filtro.toString().toLowerCase()
            if (busca.trim() == '') return this.items
                
            let listaFiltrada = []
            this.items.forEach((item) => {
                if(item.nomeConta.toLowerCase().match(busca)) listaFiltrada.push(item) 
                if(item.anoMesRef.toString().match(busca)) listaFiltrada.push(item) 
                if(item.userCreate.toLowerCase().toString().match(busca)) listaFiltrada.push(item) 
                if(item.dataCreate.toLowerCase().toString().match(busca)) listaFiltrada.push(item) 
                if(item.saldo && item.saldo.toString().match(busca)) listaFiltrada.push(item) 
                if(item.status.toLowerCase().toString().match(busca)) listaFiltrada.push(item) 
            })

            return [...new Set(listaFiltrada)]
        },
    },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */

.btnRight {
  /* float: right; */
  height: 40px;
  width: 220px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  /* font-size: 18px; */
  font-weight: bold;
}

#page-fechamentos h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

.sel-datas {
    display: flex;
    flex-direction: row;
}

#sel-mes {
    width: 200px;
    margin-top: 34px;
    margin-left: -140px;
}

.sel-ano {
    width: 140px;
    margin-top: 34px;
    margin-left: 10px;
}

.texto-data {
    width: 140px;
    margin-top: 0px;
    /* margin-left: -10px; */
}

.tabela {
  width: 80vw;
}

.cursor {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

    

}
</style>